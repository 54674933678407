<template>
  <div>
    <label class="font-semibold">
      {{ $t('contractor-form.multimedia') }} /
      {{ $t('contractor-form.documents') }}
    </label>
    <div class="mt-4">
      <div class="flex">
        <span>{{ $t('contractor-form.multimedia') }}</span>
        <button
          v-if="mobileView"
          @click="$emit('open-sidebar', { tab: 'documents' })"
        >
          <icon :icon="EditSvg" class="ml-2 text-primary"></icon>
        </button>
      </div>
      <div class="file-list">
        <upload-list
          v-if="computedAttachments('multimedia').length"
          type="multimedia"
          :list="computedAttachments('multimedia')"
          class="mt-6"
          read-only
          grid-display
        />
        <p v-else class="file-list-placeholder">
          {{ $t('contractor-form.multimedia-placeholder') }}
        </p>
      </div>
    </div>
    <div class="mt-4">
      <div class="flex">
        <span>{{ $t('contractor-form.documents') }}</span>
        <button
          v-if="mobileView"
          @click="$emit('open-sidebar', { tab: 'documents' })"
        >
          <icon :icon="EditSvg" class="ml-2 text-primary"></icon>
        </button>
      </div>
      <div class="file-list">
        <upload-list
          v-if="computedAttachments('document').length"
          type="document"
          :list="computedAttachments('document')"
          grid-display
          read-only
        />
        <p v-else class="file-list-placeholder">
          {{ $t('contractor-form.documents-placeholder') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import UploadList from '@/components/Uploader/UploadList';
import { mapGetters, mapState } from 'vuex';
import EditSvg from '@/assets/icons/edit.svg';

export default {
  components: {
    Icon,
    UploadList,
  },
  data() {
    return { EditSvg };
  },
  computed: {
    ...mapState('attachment', ['attachments', 'uploadQueue', 'deleteQueue']),
    ...mapGetters('attachment', ['computedAttachments']),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
  },
};
</script>

<style lang="scss" scoped>
.file-list {
  @apply border px-2 mt-2 border-gray-light rounded;

  &-placeholder {
    @apply text-gray-dark text-3xl py-4 text-center;
  }
}

.list-placeholder {
  @apply text-lg text-gray-dark py-2;

  button {
    @apply flex items-center justify-center w-full;
  }
}
</style>
