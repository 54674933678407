var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('image-uploader',{staticClass:"mt-6 mb-1",model:{value:(_vm.form.logoUrl),callback:function ($$v) {_vm.$set(_vm.form, "logoUrl", $$v)},expression:"form.logoUrl"}}),_c('form',{ref:"form",staticClass:"form py-1",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-form.name'),"placeholder":_vm.$t('contractor-form.name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-form.short-name'),"placeholder":_vm.$t('contractor-form.short-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.shortName),callback:function ($$v) {_vm.$set(_vm.form, "shortName", $$v)},expression:"form.shortName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-form.nip'),"placeholder":_vm.$t('contractor-form.nip-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.nip),callback:function ($$v) {_vm.$set(_vm.form, "nip", $$v)},expression:"form.nip"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|length:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-form.regon'),"placeholder":_vm.$t('contractor-form.regon-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.regon),callback:function ($$v) {_vm.$set(_vm.form, "regon", $$v)},expression:"form.regon"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"numeric|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-form.krs'),"placeholder":_vm.$t('contractor-form.krs-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading},model:{value:(_vm.form.krs),callback:function ($$v) {_vm.$set(_vm.form, "krs", $$v)},expression:"form.krs"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-form.address'),"placeholder":_vm.$t('contractor-form.street-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)}),_c('div',{staticClass:"grid grid-cols-6 gap-4"},[_c('div',{staticClass:"col-start-1 col-end-3"},[_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.postalCodeRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"placeholder":_vm.$t('contractor-form.postcode-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.postalCode),callback:function ($$v) {_vm.$set(_vm.form, "postalCode", $$v)},expression:"form.postalCode"}})]}}],null,true)})],1),_c('div',{staticClass:"col-start-3 col-end-7"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"placeholder":_vm.$t('contractor-form.city-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1)])],1)]}}])}),_c('contractor-attachments',{on:{"open-sidebar":function($event){return _vm.$emit('open-sidebar', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }