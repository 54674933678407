import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import ContractorForm from '@/views/Dashboard/Contractors/Forms/ContractorForm.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations';
import Tab from '@/components/Tabs/Tab.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import FileUploader from '@/components/Uploader/FileUploader.vue';

export default {
  components: {
    Tab,
    Tabs,
    Breadcrumbs,
    Btn,
    FooterMenu,
    ContractorForm,
    WithSideNavigations,
    FileUploader,
  },
  data() {
    return {
      submissionInProgress: false,
      submissionEndSuccessfully: false,
      brand: null,
      tabs: ['documents', 'structures', 'places'],
      activeTabIndex: 0,
    };
  },
  computed: {
    ...mapState('ui', {
      showSidebar: (state) => state.sidebar.isOpen,
    }),
    ...mapState('contractor', {
      rootStructure: 'rootStructure',
      structures: 'structures',
      places: 'places',
      brands: 'brands',
      contractor: 'details',
    }),
    ...mapState('attachment', ['uploadQueue']),
  },
  async beforeRouteLeave(to, from, next) {
    await VueStore.dispatch('contractor/clearState');

    next();
  },
  watch: {
    brands(value) {
      if (this.brand && !value.find((brand) => brand.id === this.brand.id))
        this.brand = null;
    },
  },
  methods: {
    ...mapActions('contractor', ['clearState']),
    ...mapActions('ui', ['toggleSidebar']),
    ...mapActions('attachment', [
      'saveAttachments',
      'clearAttachmentList',
      'clearUploadQueue',
      'clearDeleteQueue',
    ]),
    onOpenSidebar({ tab, brand }) {
      this.activeTabIndex = this.tabs.findIndex((el) => el === tab);

      if (brand) this.brand = brand;

      if (!this.showSidebar) this.toggleSidebar();
    },
  },
};
