<template>
  <with-side-navigations side="right" :side-bar-width="50">
    <template #sideNavigation>
      <tabs
        class="max-h-screen overflow-auto"
        @select-tab="activeTabIndex = $event"
      >
        <tab
          :name="$t('contractors-view.documents')"
          :selected="activeTabIndex === 0"
          class="mb-56"
        >
          <file-uploader
            :list="uploadQueue"
            type="multimedia"
            target="contractors"
          />
          <file-uploader
            :list="uploadQueue"
            type="document"
            target="contractors"
          />
        </tab>
      </tabs>
    </template>

    <div class="container-fluid bg-gray-lighter">
      <breadcrumbs
        :path="[
          {
            name: $t('contractors-view.contractors'),
            url: { name: 'Contractors' },
          },
          { name: $t('contractors-view.add-contractor') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <contractor-form
        id="contractorForm"
        class="mb-64"
        :clear="submissionEndSuccessfully"
        :is-loading="submissionInProgress"
        @open-sidebar="onOpenSidebar"
        @on-submit="handleSubmission"
        @set-brand="brand = $event"
      />
      <footer-menu>
        <div class="flex justify-center xs:justify-between flex-wrap">
          <div class="flex">
            <btn
              :is-loading="redirectAfterSubmission && submissionInProgress"
              type="submit"
              form="contractorForm"
              class="md:w-48 mr-3"
              @click="redirectAfterSubmission = true"
            >
              {{ $t('common.save') }}
            </btn>
            <btn
              :is-loading="!redirectAfterSubmission && submissionInProgress"
              type="submit"
              form="contractorForm"
              class="w-48 md:w-auto md:mx-3"
              theme="secondary"
              :compact="mobileView"
              @click="redirectAfterSubmission = false"
            >
              {{ $t('contractors-view.save-and-add-another') }}
            </btn>
          </div>
          <btn theme="none" class="mt-2 xs:mt-0" @click="$router.go(-1)">
            {{ $t('common.cancel') }}
          </btn>
        </div>
      </footer-menu>
    </div>
  </with-side-navigations>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VueStore from '@/store';
import contractor from '@/views/Dashboard/Contractors/mixins/contractor';
import { OrganizationType } from '@/constants/organization.ts';

export default {
  name: 'AddContractor',
  mixins: [contractor],
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch('contractor/clearState'),
      VueStore.dispatch('attachment/clearAttachmentList'),
      VueStore.dispatch('attachment/clearUploadQueue'),
    ]);

    next();
  },
  data() {
    return {
      redirectAfterSubmission: false,
    };
  },
  computed: {
    ...mapState('attachment', {
      uploadQueue: 'uploadQueue',
    }),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    ...mapState('contractor', ['placesToAdd']),
    ...mapGetters('contractor', ['addedPlacesIds']),
  },
  methods: {
    ...mapActions('contractor', ['addContractor', 'addPlaceToContractor']),
    async handleSubmission({ formData }) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;

      const form = { ...formData };
      delete form.brandIds;
      delete form.structureIds;
      if (form.logoUrl === '') delete form.logoUrl;
      form.krs = form.krs || null;

      try {
        const { data } = await this.addContractor({
          type: OrganizationType.CONTRACTOR,
          ...form,
        });

        await this.saveAttachments({ id: data.id });

        this.clearAttachmentList();
        this.clearDeleteQueue();
        this.clearUploadQueue();

        this.submissionEndSuccessfully = true;

        if (this.redirectAfterSubmission)
          this.$router.push({ name: 'Contractors' });

        this.$toast.success(this.$t('contractors-view.contractor-added'));

        this.clearState();
      } catch (err) {
        this.submissionEndSuccessfully = false;
      }

      this.submissionInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
