import { extend } from 'vee-validate';
import { postalCodeRegex } from '@/util/form';
import { mapActions, mapGetters, mapState } from 'vuex';
import { length, numeric, regex, required } from 'vee-validate/dist/rules';
import difference from 'lodash.difference';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    clear: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      postalCodeRegex,
      selectedBrands: [],
      emptyForm: {
        logoUrl: '',
        name: '',
        shortName: '',
        nip: '',
        regon: '',
        krs: '',
        address: '',
        postalCode: '',
        city: '',
      },
      form: {},
    };
  },
  computed: {
    ...mapState('contractor', [
      'brands',
      'places',
      'details',
      'placesToRemove',
    ]),
    ...mapGetters('contractor', ['structuresIds']),
  },
  watch: {
    brands: {
      immediate: true,
      handler(value) {
        this.selectedBrands = [...value];
      },
    },
    selectedBrands: {
      immediate: true,
      handler(brands) {
        if (brands.length) {
          const lastBrand = brands[brands.length - 1];

          this.$emit('set-brand', lastBrand);
        }
      },
    },
    clear(val) {
      if (val) this.clearForm();
    },
    content: {
      immediate: true,
      handler(data) {
        if (!data) this.form = { ...this.emptyForm };
        else {
          const newContent = {};
          const formKeys = Object.keys(this.emptyForm);

          Object.entries(data).forEach(([key, value]) => {
            if (formKeys.includes(key) && value) newContent[key] = value;
          });
          this.form = { ...this.form, ...newContent };
        }
      },
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });

    extend('numeric', {
      ...numeric,
      message: this.$t('errors.error-numeric'),
    });

    extend('length', {
      ...length,
      message: this.$t('errors.error-length'),
    });

    extend('regex', {
      ...regex,
      message: this.$t('errors.error-regex'),
    });
  },
  methods: {
    ...mapActions('contractor', ['setBrands', 'fetchPlaces', 'removePlace']),
    pushFormData() {
      const formData = {
        ...this.form,
        hasInvoiceData: false,
        brandIds: this.brands.map((brand) => brand.id),
        structureIds: this.structuresIds,
      };

      const removeLogo =
        !!this.content && !!this.content.logoUrl && formData.logoUrl === '';

      this.$emit('on-submit', { formData, removeLogo });
    },
    clearForm() {
      this.form = { ...this.emptyForm };

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async fetchRemovedPlaceIds(brandId, limit = 10) {
      const { data } = await this.fetchPlaces({
        brandId,
        relatedContractorId: this.details.id,
        limit,
      });

      const {
        results,
        pagination: { total, to },
      } = data;

      if (total > to) {
        return this.fetchRemovedPlaceIds(brandId, total);
      }

      return results.map(({ id }) => id);
    },
    async onUpdateBrands(brands) {
      const currentBrandIds = this.brands.map((brand) => brand.id);
      const newBrandIds = brands.map((brand) => brand.id);

      const removedBrands = difference(currentBrandIds, newBrandIds);

      if (this.edit) {
        await Promise.all(
          removedBrands.map(async (brandId) => {
            const removedIds = await this.fetchRemovedPlaceIds(brandId);

            removedIds.forEach((id) => {
              if (!this.placesToRemove[brandId].includes(id))
                this.removePlace({ id, brandId });
            });
          }),
        );
      }

      this.setBrands(brands);
    },
  },
};
