<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, reset }">
      <image-uploader v-model="form.logoUrl" class="mt-6 mb-1" />
      <form
        :id="id"
        ref="form"
        class="form py-1"
        @submit.prevent="handleSubmit(pushFormData)"
        @reset.prevent="reset"
      >
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="form.name"
            :label="$t('contractor-form.name')"
            :placeholder="$t('contractor-form.name-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
            class="mt-5"
          ></text-input>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="form.shortName"
            :label="$t('contractor-form.short-name')"
            :placeholder="$t('contractor-form.short-name-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
            class="mt-5"
          ></text-input>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|numeric|length:10"
        >
          <text-input
            v-model="form.nip"
            :label="$t('contractor-form.nip')"
            :placeholder="$t('contractor-form.nip-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
            class="mt-5"
          ></text-input>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|numeric|length:9"
        >
          <text-input
            v-model="form.regon"
            :label="$t('contractor-form.regon')"
            :placeholder="$t('contractor-form.regon-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
            class="mt-5"
          ></text-input>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="numeric|length:10">
          <text-input
            v-model="form.krs"
            :label="$t('contractor-form.krs')"
            :placeholder="$t('contractor-form.krs-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            class="mt-5"
          ></text-input>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="form.address"
            :label="$t('contractor-form.address')"
            :placeholder="$t('contractor-form.street-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
            class="mt-5"
          ></text-input>
        </ValidationProvider>

        <div class="grid grid-cols-6 gap-4">
          <div class="col-start-1 col-end-3">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ regex: postalCodeRegex, required: true }"
            >
              <text-input
                v-model="form.postalCode"
                :placeholder="$t('contractor-form.postcode-placeholder')"
                :error-message="errors[0]"
                :disabled="isLoading"
                required
                class="mt-5"
              ></text-input>
            </ValidationProvider>
          </div>
          <div class="col-start-3 col-end-7">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <text-input
                v-model="form.city"
                :placeholder="$t('contractor-form.city-placeholder')"
                :error-message="errors[0]"
                :disabled="isLoading"
                required
                class="mt-5"
              ></text-input>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <contractor-attachments @open-sidebar="$emit('open-sidebar', $event)" />
  </div>
</template>

<script>
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import { TextInput } from '@/components/Inputs';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ContractorAttachments from '@/views/Dashboard/Contractors/_components/ContractorAttachments';
import contractorForm from '@/views/Dashboard/Contractors/mixins/contractorForm';

export default {
  components: {
    ContractorAttachments,
    ImageUploader,
    TextInput,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [contractorForm],
};
</script>
